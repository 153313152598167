import React, { useEffect, useState } from 'react';
import { openDB } from 'idb';
import './App.css'; // Assuming the styles are in App.css

function AccessPage() {
  const [pdfs, setPdfs] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(true); // Hardcoded to true for testing

  useEffect(() => {
    // const checkAuthorization = async () => {
    //   const db = await openDB('AuthDB', 1);
    //   const authStatus = await db.get('auth', 'authStatus');
    //   setIsAuthorized(authStatus === 'true');
    // };

    const fetchPdfs = async () => {
      // Simulating fetching PDF data
      const pdfData = [
        { id: 1, title: 'Survival Guide', cover: 'https://surviveoffline.com/img/SurviveOffline2.jpg', link: '/path/to/pdf1.pdf' },
        // Add more PDFs here
      ];
      setPdfs(pdfData);
    };

    // checkAuthorization(); // Commented out for now
    fetchPdfs();
  }, []);

  return (
    <div className="App Appoffline">
      <div className="container">
        <div className="offline-content">
          <header className="App-header">
            <h1 className="main-heading">
              <span className="highlightoffline">Available Resources</span>
            </h1>
          </header>
          <div className="bookshelf-section">
            <div className="bookshelf">
              {pdfs.map(pdf => (
                <div key={pdf.id} className="book">
                  <img src={pdf.cover} alt={pdf.title} />
                  <a href={pdf.link} download>{pdf.title}</a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessPage;
