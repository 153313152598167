import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PDFViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pdfUrl } = location.state || {};

  const handleReturn = () => {
    navigate('/');
  };

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      {pdfUrl ? (
        <>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            width="100%"
            height="100%"
            style={{ position: 'absolute', top: 0, left: 0, border: 'none' }}
          />
          <button
            onClick={handleReturn}
            style={{
              position: 'fixed',
              top: '20px',
              left: '20px',
              zIndex: 1000,
              backgroundColor: 'rgba(68, 68, 68, 0.8)',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              padding: '10px 20px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(68, 68, 68, 1)'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgba(68, 68, 68, 0.8)'}
          >
            Return to SurvivalShield
          </button>
        </>
      ) : (
        <p>PDF is not available.</p>
      )}
    </div>
  );
};

export default PDFViewer;