import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Detector } from 'react-detect-offline';
import { openDB } from 'idb';
import './App.css';
import spinner from './spinner.gif';
import fonts from './fonts.png';
import './css_sprites.png';
import PDFViewer from './PDFViewer'; // Import the PDFViewer component
import { CACHE_VERSION } from './cacheConfig'; // Import cache version

// Helper function to compute dbVersion from CACHE_VERSION
const getDbVersion = () => {
  const versionParts = CACHE_VERSION.split('.').map(Number);
  const major = versionParts[0] || 0;
  const minor = versionParts[1] || 0;
  const patch = versionParts[2] || 0;
  return major * 10000 + minor * 100 + patch;
};

const poem = `
The world breaks everyone, and afterward, some are strong at the broken places.
— Ernest Hemingway
`;

let globalState = {
  readyForPayment: false,
  email: ''
};

const fetchPDF = async (pdfId, navigate) => {
  try {
    const dbVersion = getDbVersion();
    const db = await openDB('ResourceDB', dbVersion);
    const resource = await db.get('resources', pdfId);

    if (resource && resource.blob) {
      const file = new File([resource.blob], `${pdfId}.pdf`, { type: 'application/pdf' });
      const url = URL.createObjectURL(file);

      // Detect Safari on iOS
      const isSafariIOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isSafariIOS) {
        // Open in the same window for Safari iOS
        window.location.href = url;
      } else {
        // For other browsers, create a link and open in a new tab
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';  // Open in a new tab

        // Avoid download prompt on Chrome iOS
        const isChromeIOS = /CriOS/.test(navigator.userAgent);
        if (!isChromeIOS) {
          link.download = `${pdfId}.pdf`;  // Only set download for non-Chrome iOS
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      alert('PDF not available offline yet!');
    }
  } catch (error) {
    console.error('Error fetching PDF:', error);
  }
};









function App() {
  const [storedPoem, setStoredPoem] = useState('');
  const [readyForPayment, setReadyForPayment] = useState(globalState.readyForPayment);
  const [email, setEmail] = useState(globalState.email);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  // **Moved checkCacheStatus function here**
  const checkCacheStatus = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      console.log('Service Worker is ready and handling caching.');
  
      const dbVersion = getDbVersion();
      const db = await openDB('ResourceDB', dbVersion);
      const cachedPDF1 = await db.get('resources', 'SurviveOffline'); 
      const cachedPDF2 = await db.get('resources', 'CivilWar');

      if (cachedPDF1 && cachedPDF2) {
        document.querySelector('.cache-dot').style.backgroundColor = 'green'; // Green dot
        console.log('Content is cached for offline use.');
      } else {
        document.querySelector('.cache-dot').style.backgroundColor = 'red'; // Red dot if one or both are missing
        setTimeout(() => {
          const cacheFailAlert = document.getElementById('cache-fail-alert');
          if (cacheFailAlert && document.querySelector('.cache-dot').style.backgroundColor === 'red') {
            cacheFailAlert.style.display = 'block'; // Show the alert if the cache is still red after 12 seconds
          }
        }, 12000); // Wait 12 seconds before showing the alert
      }
    } catch (error) {
      console.error('Error checking cache status:', error);
      document.querySelector('.cache-dot').style.backgroundColor = 'red'; // Red dot on error
      setTimeout(() => {
        const cacheFailAlert = document.getElementById('cache-fail-alert');
        if (cacheFailAlert) {
          cacheFailAlert.style.display = 'block'; // Show the alert in case of an error
        }
      }, 12000); // Wait 12 seconds before showing the alert on error
    }
  };

  useEffect(() => {
    const initDB = async () => {
      try {
        console.log('Initializing ResourceDB...');
        const dbVersion = getDbVersion();
        const db = await openDB('ResourceDB', dbVersion, {
          upgrade(db, oldVersion, newVersion, transaction) {
            // Upgrade logic...
            if (!db.objectStoreNames.contains('resources')) {
              db.createObjectStore('resources', { keyPath: 'id' });
              console.log('Created object store "resources".');
            }
            if (!db.objectStoreNames.contains('meta')) {
              db.createObjectStore('meta', { keyPath: 'key' });
              console.log('Created object store "meta".');
            }
          },
        });
  
        const currentCacheVersion = CACHE_VERSION;
        const storedCacheVersion = await db.get('meta', 'cacheVersion');
  
        if (storedCacheVersion?.value !== currentCacheVersion) {
          console.log('Cache version changed. Updating PDFs in IndexedDB.');
  
          // Clear the 'resources' object store
          const tx = db.transaction('resources', 'readwrite');
          tx.objectStore('resources').clear();
          await tx.done;
  
          // Update the cache version in IndexedDB
          await db.put('meta', { key: 'cacheVersion', value: currentCacheVersion });
        } else {
          console.log('Cache version unchanged. Using existing PDFs.');
        }
  
        // **Code to fetch and store PDFs**
        const pdfUrl1 = '/resources/SurviveOffline.pdf';
        const pdfUrl2 = '/resources/CivilWar-DelMcCool.pdf';
  
        const fetchAndStorePDF = async (id, url) => {
          try {
            console.log(`Fetching ${id} from ${url}`);
            const response = await fetch(url);
            const blob = await response.blob();
            // Store the blob with its type
            await db.put('resources', { id, blob: blob, type: blob.type });
            console.log(`${id} cached successfully.`);
          } catch (error) {
            console.error(`Error fetching and storing ${id}:`, error);
          }
        };
  
        // Fetch and cache PDFs
        await Promise.all([
          fetchAndStorePDF('SurviveOffline', pdfUrl1),
          fetchAndStorePDF('CivilWar', pdfUrl2),
        ]);
  
        // After caching is complete, check cache status
        checkCacheStatus();
  
      } catch (error) {
        console.error('Error initializing DB or fetching PDFs:', error);
      }
    };
  
    initDB();
  }, [isOnline]);
  

  

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(() => {
        checkCacheStatus();
      });
    }
  }, []);

  useEffect(() => {
    const fetchPoem = async () => {
      try {
        const poemDBVersion = 2; // Increment the version number
        const db = await openDB('PoemDB', poemDBVersion, {
          upgrade(db, oldVersion, newVersion, transaction) {
            if (!db.objectStoreNames.contains('poem')) {
              db.createObjectStore('poem', { keyPath: 'id' });
              console.log('Created object store "poem".');
            }
          },
        });
  
        // Check if the poem is already stored
        let poemData = await db.get('poem', 'offlinePoem');
  
        if (!poemData) {
          // If not, store the poem
          poemData = {
            id: 'offlinePoem',
            text: `
              In the quiet of the offline night,
              Disconnected from the world's light,
              A poem whispers through the air,
              A gentle reminder that we still care.
            `,
          };
          await db.put('poem', poemData);
          console.log('Stored poem in IndexedDB.');
        }
  
        setStoredPoem(poemData.text);
      } catch (error) {
        console.error('Error fetching poem:', error);
      }
    };
  
    fetchPoem();
  }, [isOnline]);
  

  useEffect(() => {
    const handleOnlineStatus = () => {
      console.log('Network status changed:', navigator.onLine);
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  const handlePrepareForPayment = () => {
    globalState.readyForPayment = true;
    globalState.email = email;
    setReadyForPayment(true);
    setModalIsOpen(true);
  };

  // Function to refresh the page
  const refreshPage = () => {
    window.location.reload();
  };

  const handlePayment = async () => {
    if (!isOnline) {
      alert('You need to be online to complete the payment.');
      return;
    }

    try {
      const response = await fetch('https://surviveoffline.com/API-Survival/createcheckoutsession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const session = await response.json();
      if (session.error) {
        throw new Error(session.error);
      }

      // Redirect to Stripe Checkout page
      window.location.href = session.url;
    } catch (error) {
      console.error('Error during payment process:', error);
      alert('There was an error processing your payment. Please try again.');
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Detector
      render={({ online }) => {
        setIsOnline(online); // Update isOnline state based on the Detector's result
        return (
          <div className={`App ${modalIsOpen ? 'modal-open' : ''} ${online ? 'Apponline' : 'Appoffline'}`}>
            <div className={`ourcontainer ${online ? '' : 'outercontaineroffline'}`}>
              <div id="paymentModal" className={`modal ${modalIsOpen ? 'show' : ''}`}>
                <div className="modal-content">
                  <span className="close" onClick={closeModal}>&times;</span>
                  {online ? (
                    <>
                      <h2>Set Up Your SurviveOffline<br></br>30 Day Free Trial</h2>
                      <p>Email: {email}</p>
                      <button className="cta-button ctaenabled" onClick={handlePayment}>Continue Setup</button>
                    </>
                  ) : (
                    <>
                      <h2>Reconnect to Complete</h2>
                      <p>Connect to the internet to complete signup.</p>
                      <img src={spinner} alt="Loading..." className="loader" />
                      <button className="cta-button disabled" disabled>
                        Connect to Unlock Your 30 Days FREE!
                      </button>
                    </>
                  )}
                </div>
              </div>

              <header className="App-header">
                <div className="container">
                  {online ? (
                    <div className="online-view">
                      <h1 className="main-heading">Survive<u className='headertitle'>OFFLINE</u></h1>
                      <h2 className="sub-heading sub-headingonline">Toggle <span className="shake-airplane">Airplane Mode</span> to access!</h2>
                      
                      <div className="cache-status" onClick={refreshPage}>
                        Cached (V{CACHE_VERSION})
                        <div className="cache-dot"></div>
                      </div>


                      {/* Alert message for caching failure */}
                      <div id="cache-fail-alert" className="cache-fail-alert" style={{ display: 'none', color: 'red' }}>
                        Failed to cache, make sure you're not in incognito mode
                      </div>

                    </div>
                  ) : (
                    <div className="offline-view">
                      <section className="hero">
                        <div className="hero-content">
                          <h1 className="main-heading">
                            <span className="highlightoffline">YOU'RE OFFLINE! Welcome to your resource, for when the world goes dark.</span>
                          </h1>
                        </div>
                      </section>

                      <div className="offline-content">
                        <section className="feature-highlights">
                          <h3>Offline Resources:</h3>
                          <div className="feature-grid">
                            <div className="bookshelf">
                            <a href="#" onClick={(e) => { e.preventDefault(); fetchPDF('SurviveOffline', navigate); }}>
                              <img src="https://surviveoffline.com/img/SurviveOffline2.jpg" alt="Survive Offline" />
                            </a>
                            <a href="#" onClick={(e) => { e.preventDefault(); fetchPDF('CivilWar', navigate); }}>
                              <img src="https://surviveoffline.com/img/SurvivalGuide2-small.jpg" alt="Civil War" />
                            </a>

                              <div className="book"><img src="https://surviveoffline.com/img/comingsoon.jpg" alt="Survival Guide" /></div>
                              <div className="book"><img src="https://surviveoffline.com/img/comingsoon.jpg" alt="Survival Guide" /></div>
                              <div className="book"><img src="https://surviveoffline.com/img/comingsoon.jpg" alt="Survival Guide" /></div>
                              <div className="book"><img src="https://surviveoffline.com/img/comingsoon.jpg" alt="Survival Guide" /></div>
                            </div>
                          </div>
                        </section>

                        <section className="offline-section">
                          <p>The grid is fragile. One solar flare, one cyber attack, one natural disaster, and it's gone.</p>
                          <p>This is your lifeline in the darkness - and it could be the difference between life and death.</p>
                          <p>And it will all be available to you, no internet required. No cell service needed. No cost.</p>
                          <p>Just raw, vital information at your fingertips, when you need it most!</p>
                          <p>The world is unpredictable. Governments fall. Natural disasters strike. Pandemics spread. The only question is: Will you be ready?</p>
                        </section>

                        <section className="offline-section">
                        <h3>Support Our Community:</h3>
                        <p>When the Wi-Fi fades, knowledge shouldn't. This community is more than just a few authors accessible when offline — it's an act of rebellion. 
                            A stand against tech monopolies and vulnerable networks, ensuring information remains free, accessible, and beyond control.</p>
                          <p>The best way to support this community is to purchase physical copies from our contributing authors. After all, a physical book is the ultimate backup, readable even during solar flare events.</p><br></br>
                           <p>If you'd like to help keep this service free and support our server costs, we welcome small BTC lightning donations:<br></br>
                          <span class="lnurl">LNURL1DP68GURN8GHJ7AMPD3KX2AR0VEEKZAR0WD5XJTNRDAKJ7TNHV4KXCTTTDE</span></p>
                        </section>

                      </div>

                      <footer>
                        <p className="poem">{storedPoem}</p>
                      </footer>
                    </div>
                  )}
                </div>
              </header>
            </div>
          </div>
        );
      }}
    />
  );
}

export default App;
